import { CommerceLayerOrder } from "../../services/CommerceLayerApi/OrderService/types";
import { Customer } from "../../services/WaterTaxiApi/CustomerService/types";
import { SET_CUSTOMER } from "./constants";

export type AuthState = {
  customer: Customer | null;
};

const initialState: AuthState = {
  customer: null,
};

export const authReducer = (
  state: AuthState = initialState,
  action: any
): AuthState => {
  switch (action.type) {
    case SET_CUSTOMER:
      return {
        ...state,
        customer: action.payload,
      };
    default:
      return state;
  }
};
