export const SET_ORDER = "SET_ORDER";
export const SET_ORDER_LINE_ITEMS = "SET_ORDER_LINE_ITEMS";
export const SET_LOADING = "SET_LOADING";
export const SET_COUNTRIES = "SET_COUNTRIES";
export const SET_PAYMENT_METHOD = "SET_PAYMENT_METHOD";
export const SET_PAYMENT_SOURCE = "SET_PAYMENT_SOURCE";
export const ADD_SNACKBAR = "ADD_SNACKBAR";
export const REMOVE_SNACKBAR = "REMOVE_SNACKBAR";

// LEGACY

export const UPDATE_ORDER = "UPDATE_ORDER"
export const UPDATE_CURRENT_STEP = "UPDATE_CURRENT_STEP"
export const UPDATE_REQUIRES_DELIVERY = "UPDATE_REQUIRES_DELIVERY"
export const UPDATE_REQUIRES_PAYMENT = "UPDATE_REQUIRES_PAYMENT"
export const UPDATE_ORDER_PAYMENT_SOURCE = "UPDATE_ORDER_PAYMENT_SOURCE"
export const UPDATE_BUTTON_LOADING_CUSTOMER = "UPDATE_BUTTON_LOADING_CUSTOMER"
export const UPDATE_BUTTON_LOADING_DELIVERY = "UPDATE_BUTTON_LOADING_DELIVERY"
export const UPDATE_BUTTON_LOADING_PAYMENT = "UPDATE_BUTTON_LOADING_PAYMENT"
export const PAYMENT_BUTTON_CLICKED = "PAYMENT_BUTTON_CLICKED";
export const UPDATE_APPLY_COUPON_ERROR = "UPDATE_APPLY_COUPON_ERROR"
export const UPDATE_GIFTCARD_OR_COUPON_APPLIED = "UPDATE_GIFTCARD_OR_COUPON_APPLIED"
export const UPDATE_GIFTCARD_OR_COUPON_CODE = "UPDATE_GIFTCARD_OR_COUPON_CODE"
export const UPDATE_SET_ADDRESSES_ERROR = "UPDATE_SET_ADDRESSES_ERROR"
export const UPDATE_AUTH_HAS_CUSTOMER = "UPDATE_AUTH_HAS_CUSTOMER"
export const UPDATE_AUTH_ACCESS_TOKEN = "UPDATE_AUTH_ACCESS_TOKEN"
export const CLEAR_AUTH_ACCESS_TOKEN = "CLEAR_AUTH_ACCESS_TOKEN"
export const UPDATE_AUTH_REFRESH_TOKEN = "UPDATE_AUTH_REFRESH_TOKEN"
export const UPDATE_CUSTOMER_ADDRESSES = "UPDATE_CUSTOMER_ADDRESSES"
export const UPDATE_CUSTOMER_SUBSCRIPTION_ID = "UPDATE_CUSTOMER_SUBSCRIPTION_ID"
export const UPDATE_CUSTOMER_SUBSCRIPTION_CHECKED = "UPDATE_CUSTOMER_SUBSCRIPTION_CHECKED"
export const DISABLE_CUSTOMER_SUBSCRIPTION = "DISABLE_CUSTOMER_SUBSCRIPTION"
export const UPDATE_CUSTOMER_EMAIL = "UPDATE_CUSTOMER_EMAIL"
export const UPDATE_INVALID_CUSTOMER = "UPDATE_INVALID_CUSTOMER"
export const UPDATE_INVALID_BILLING_ADDRESS = "UPDATE_INVALID_BILLING_ADDRESS"
export const UPDATE_INVALID_SHIPPING_ADDRESS = "UPDATE_INVALID_SHIPPING_ADDRESS"
export const UPDATE_BILLING_ADDRESS = "UPDATE_BILLING_ADDRESS"
export const UPDATE_SHIPPING_ADDRESS = "UPDATE_SHIPPING_ADDRESS"
export const UPDATE_ERROR_APPLY_GIFT_CARD_OR_COUPON = "UPDATE_ERROR_APPLY_GIFT_CARD_OR_COUPON"
export const UPDATE_ERROR_SET_ADDRESSES = "UPDATE_ERROR_SET_ADDRESSES"
export const UPDATE_PLACE_ORDER_ERROR = "UPDATE_PLACE_ORDER_ERROR"
export const UPDATE_SHIP_TO_DIFFERENT_ADDRESS_REQUIRED = "UPDATE_SHIP_TO_DIFFERENT_ADDRESS_REQUIRED"
export const UPDATE_SHIP_TO_DIFFERENT_ADDRESS = "UPDATE_SHIP_TO_DIFFERENT_ADDRESS"
export const UPDATE_INVALID_SHIPMENTS = "UPDATE_INVALID_SHIPMENTS"
export const UPDATE_INVALID_PAYMENT_METHOD = "UPDATE_INVALID_PAYMENT_METHOD"
export const UPDATE_PAYMENT_OPTION = "UPDATE_PAYMENT_OPTION"