import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";

import { ecommerceReducer } from "./ecommerce/reducer";
import { authReducer } from "./auth/reducer";

const reducer = combineReducers({
  ecommerce: ecommerceReducer,
  auth: authReducer,
});

const middleware = [thunk];

const store = createStore(reducer, {}, applyMiddleware(...middleware));

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;

export default store;
