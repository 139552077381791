import { CommerceLayerOrder } from "../../services/CommerceLayerApi/OrderService/types";
import {
  SET_ORDER,
  SET_LOADING,
  SET_COUNTRIES,
  SET_PAYMENT_METHOD,
  SET_PAYMENT_SOURCE,
  ADD_SNACKBAR,
  REMOVE_SNACKBAR,
  SET_ORDER_LINE_ITEMS,
} from "./constants";

export type EcommerceState = {
  order: CommerceLayerOrder | null;
  loading: boolean;
  countries: any[];
  auth: any;
  snackbar: SnackbarType[];
};

export type SnackbarType = {
  id: number;
  open: boolean;
  message: string;
};

const initialState: EcommerceState = {
  order: null,
  loading: true,
  countries: [],
  auth: null,
  snackbar: [],
};

export const ecommerceReducer = (
  state: EcommerceState = initialState,
  action: any
): EcommerceState => {
  switch (action.type) {
    case SET_ORDER:
      return {
        ...state,
        order: action.payload,
      };
    case SET_ORDER_LINE_ITEMS:
      return {
        ...state,
        order: state.order && {
          ...state.order,
          line_items: action?.payload,
        },
      };
    case SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case SET_COUNTRIES:
      return {
        ...state,
        countries: action.payload,
      };
    case SET_PAYMENT_METHOD:
      return {
        ...state,
        order: state.order && {
          ...state.order,
          payment_method: action?.payload,
        },
      };
    case SET_PAYMENT_SOURCE:
      return {
        ...state,
        order: state.order && {
          ...state.order,
          payment_source: action?.payload,
        },
      };
    case ADD_SNACKBAR:
      const snackbar = state.snackbar;
      const id = snackbar.length + 1;

      const newSnackbar = {
        id,
        open: true,
        message: action.payload,
      };

      return {
        ...state,
        snackbar: [...state.snackbar, newSnackbar],
      };
    case REMOVE_SNACKBAR:
      return {
        ...state,
        snackbar: state.snackbar.filter(
          (snackbar) => snackbar.id !== action.payload
        ),
      };
    default:
      return state;
  }
};
