import React from "react";

// scss
import "./src/styles/global.scss";

// redux
import { Provider } from "react-redux";
import store from "./src/redux/store";

export const wrapPageElement = ({ element }) => (
  <Provider store={store}>{element}</Provider>
);
